import React from 'react';

import Layout from 'src/layout/Layout';
import SEO from 'src/components/global/seo';
import AnalyticsScript from 'src/components/Analytics';

export default () => (
  <Layout className="not-found">
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn't exist... the sadness.</p>
    <AnalyticsScript />
  </Layout>
);
